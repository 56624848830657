'use strict';

export default class Contact {
	constructor() {
		// uses jQuery because of shoestring from tablesaw
		jQuery('.contact .form').on('submit', function(e){
			var $this = jQuery('.contact .form'),
				submitUrl = $this.attr('action');

			jQuery('.js-loader').show();

			jQuery.ajax(submitUrl, {
				method: 'POST',
				data: $this.serialize(),
				success: function() {
					jQuery('.contact__text').show();
					jQuery('.js-loader').hide();
				},
				fail: function(data) {
					console.error('Error: ', data);
				}
			});

			e.preventDefault();
			return false;
		});
	}
}

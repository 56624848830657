'use strict';

import $ from 'jquery';
import 'slick-carousel';

export default class Gallery {
	constructor() {
		$('.gallery__main').slick({
			slidesToShow: 1,
			slidesToScroll: 1,
			infinite: true,
			arrows: false,
			fade: true
		});
		$('.gallery__carousel').slick({
			slidesToShow: 3,
			slidesToScroll: 1,
			infinite: true,
			prevArrow: '<button type="button" class="slick-prev slick-arrow"><span class="vh">Previous</span><i class="icon icon-arrow-left"></button>',
			nextArrow: '<button type="button" class="slick-next slick-arrow"><span class="vh">Next</span><i class="icon icon-arrow-right"></button>',
			mobileFirst: true,
			responsive: [
				{
					breakpoint: 1023,
					settings: {
						slidesToShow: 4,
					}
				}
			],
			asNavFor: '.gallery__main',
			focusOnSelect: true
		});
	}
}

'use strict';

import $ from 'jquery';

export default class Header {
	constructor() {
		function debounce(func, wait, immediate) {
			var timeout;
			return function() {
				var context = this, args = arguments;
				var later = function() {
					timeout = null;
					if (!immediate) func.apply(context, args);
				};
				var callNow = immediate && !timeout;
				clearTimeout(timeout);
				timeout = setTimeout(later, wait);
				if (callNow) func.apply(context, args);
			};
		};

		$('.js-nav').on('click', function(e){
			e.preventDefault();
			let $this = $(this),
				target = $this.attr('href');

			let offset = $('.header').outerHeight(),
				stateObj = {  };

			$('html, body').animate({
				scrollTop: $(target).offset().top - offset
			}, 1000);

			$('.js-menu').removeClass('is-active');

			window.location.hash = target;
			history.pushState(stateObj, '', '');
		});

		$('.js-menu').on('click', function() {
			let $this = $(this);

			$this.toggleClass('is-active');
		});

		let distance = $(window).scrollTop();

		if (distance < 20) {
			$('body').removeClass('is-scrolled');
		} else {
			$('body').addClass('is-scrolled');
		}

		$(window).on('scroll', debounce(function() {
			let distance = $(window).scrollTop();

			if (distance < 20) {
				$('body').removeClass('is-scrolled');
			} else {
				$('body').addClass('is-scrolled');
			}
		}, 100));
	}
}

// Main javascript entry point
// Should handle bootstrapping/starting application

'use strict';

import objectFitImages from 'object-fit-images';
import Tablesaw from 'tablesaw/dist/stackonly/tablesaw.stackonly.js';
import Header from '../_modules/header/header';
import Concept from '../_modules/concept/concept';
import Gallery from '../_modules/gallery/gallery';
import Videos from '../_modules/videos/videos';
import Contact from '../_modules/contact/contact';

$(() => {

	objectFitImages();
	Tablesaw.init();

	new Header();
	new Concept();
	new Gallery();
	new Videos();
	new Contact();

});
